import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';


function CheckLogin( props ) {
  const [loginMessage, setLoginMessage] = useState( "" );

  let cookie_api_key = Cookies.get('api_key');


  useEffect(() => {
    if( Cookies.get('api_key') ) {
      props.setLoggedIn(cookie_api_key);
      props.setLoginEmail(Cookies.get('login_email'));
    }
  }, []);

  
  const searchParams = new URLSearchParams(window.location.search);
  const login_key = searchParams.get("login_key");
  
  const login_email = encodeURIComponent( searchParams.get("login_email") );

  if( login_key == null || login_email == null ) {
    return;
  }

  fetch(`https://api.uloz.link/wp-json/ulozlink/v1/check-login-key/?login_key=${login_key}&login_email=${login_email}`)
    .then(response => response.json())
    .then(data => {
      //console.log( data.message );
     if( data.status == 1 ) {
      Cookies.set('api_key', data.api_key, { expires: 365, path: '/' });
      props.setLoggedIn( data.api_key );

      let email_encoded = decodeURIComponent(login_email );
      Cookies.set('login_email', email_encoded, { expires: 365, path: '/' });
      props.setLoginEmail( email_encoded );

      setLoginMessage("");
      
      window.history.pushState(null, null, window.location.pathname);
     }
     else {
      
      setLoginMessage( data.message ) ;
      setTimeout(() => {
        setLoginMessage( "" ) ;
        window.history.pushState(null, null, window.location.pathname);
      }, 5000);
     }
  });

  return (
    <div>
      {loginMessage ?
      <div className="app-message">
        {loginMessage}
      </div>
      :  null}
    </div>
  )
}

export default CheckLogin;