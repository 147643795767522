import React, { useEffect, useState } from 'react';
import LinksList from './components/LinksList';
import AddLink from './components/AddLink';
import GetStarted from './components/GetStarted';


import { fetchData } from './functions/fetchLinks';


function HomePage( props ) {
  const [posts, setPosts] = useState([]);

  const fetchDataWrapper = () => {
    fetchData( props.loggedIn, setPosts );
  };


  //
  return ( 
    <main>
      <AddLink loggedIn={props.loggedIn} fetchData={fetchDataWrapper}/>
      {!props.loggedIn && < GetStarted />}
      {props.loggedIn && <LinksList loggedIn={props.loggedIn} fetchData={fetchDataWrapper} posts={posts} />}
      { !props.loggedIn ? 
      <section>
        <div className="container">
          <div className="row mb-3">
						<div className="col text-align-center">
							<h2>Manage your links</h2>
						</div>
					</div>
          <div className="row card text-align-center mb-3">
						<div className="col">
							<p>You need to log in first 😬</p>
						</div>
					</div>
        </div>
      </section>
      :
      null}
    </main>
  );
}

export default HomePage;