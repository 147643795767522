
//=== React ===
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//=== Images ===
import arrow from '../images/arrow.svg';


function GetStarted() {
  const [loginEmailInput, setLoginEmailInput] = useState('');
  const [loadingForm, setLoadingForm] = useState( false );

  const navigate = useNavigate();

  const logUser = async (event) => {
    event.preventDefault();

    let encodedEmail = encodeURIComponent( loginEmailInput );

    //Udělej kolečko přes button
    setLoadingForm( true );

    fetch(`https://api.uloz.link/wp-json/ulozlink/v1/login-user/?login_email=${encodedEmail}`)
    .then(response => response.json())
    .then(data => {
      setLoadingForm( false );
      //console.log( "email odeslán" );
      navigate('/sent');
    });
  };

  return (
    <section>
      <div className="container">
        <div className="row will-be-hidden-message mb-5">
          <div className="col">
            <p>These sections won't be here <br />after login 😉 
              <img src={arrow}alt="arrow icon" />
            </p>
          </div>
        </div>
  
        <div className="row" id="login">
          <div className="col">
            <h2 className="h1">4 easy steps</h2>
          </div>
        </div>
        
        <div className="row mt-3">
          <div className="col">
            <div className="card text-align-center">
              <h3 className="h2">Type email</h3>
              <p>Your email will be used only for login to our app. Nothing else.</p>

              <form onSubmit={logUser}>
                <input
                  className="input"
                  type="email"
                  value={loginEmailInput}
                  placeholder="your email"
                  required="required"
                  onChange={(event) => setLoginEmailInput(event.target.value)}
                />
                
                <label className="toggle">
                  <input type="checkbox" name="rememberme" value="1" checked="checked" readOnly></input>
                  <span className="toggle__inner"></span>
                  <span className="toggle__text">remember me for 1 year</span>
                </label>

                <button type="submit" className={`button ${loadingForm ? 'button--loading' : null}`} name="setupAccout" data-setupaccount="true" {...(loadingForm  ? { 'disabled': 'disabled' } : {} ) }>log via email</button>
              </form>
            </div>
          </div>
          <div className="col">
            <div className="card card--green text-align-center mt-2">
              <h3 className="h2">Verify email</h3>
              <p>Click on the link in the email. App will automatically log you in.</p>
            </div>
          </div>
          <div className="col">
            <div className="card text-align-center">
              <h3 className="h2">Paste link</h3>
              <p>Now you can paste links into field below.</p>
            </div>
          </div>
          <div className="col">
            <div className="card card--green text-align-center mt-4">
              <h3 className="h2">Get link</h3>
              <p>In around 1000 ms to 2000 ms, you will get new short link. <strong>Enjoy</strong>.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container container--big mt-5">
        <div className="row">
          <div className="col">
            <div className="card card--green">
              <div className="container mt-6 mb-6">
                <div className="row">
                  <div className="col">
                    <h2 className="h1">Why uloz.link?</h2>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col">
                    <h3 className="h3 color-blue">No ads</h3>
                    <p>This app has been build for our personal usage. Ads would be extremely intrusive.</p>
                  </div>
                  <div className="col">
                    <h3 className="h3 color-blue">Unlimited links</h3>
                    <p>You can use as much as you want. Current limitation is 5 links per user per day.</p>
                  </div>
                  <div className="col">
                    <h3 className="h3 color-blue">Fast & easy use</h3>
                    <p>Just login, paste your link and get short link in return. See clicks, delete links and more.</p>
                  </div>
                  <div className="col">
                    <h3 className="h3 color-blue">Pretty links</h3>
                    <p>You can setup you own pretty links. Feel free to change them to anything.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GetStarted;