//=== React ===
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//=== Pages ===
import HomePage from './HomePage';
import Sent from './Sent';

//=== Components ===
import Header from './components/Header';
import CheckLogin from './components/CheckLogin';
import Footer from './components/Footer';

//=== Styles ===
import './App.css';




//=== React ===

//=== Pages ===

//=== Components ===

//=== Functions ===

//=== Styles ===

//=== Images ===





//https://api.uloz.link/wp-json/ulozlink/v1/new-link/?api_key=ahoj&link=novy_link
//https://api.uloz.link/wp-json/ulozlink/v1/login-user/?login_email=stejskycze@gmail.com
//https://api.uloz.link/wp-json/ulozlink/v1/check-login-key/?login_email=stejskycze@gmail.com&&login_key==XX


function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginEmail, setLoginEmail] = useState(false);

  function handleLoggedIn(newValue) {
    setLoggedIn(newValue);
  }
  function handleLoginEmail(newValue) {
    setLoginEmail(newValue);
  }

   
  //TODO: styly hlášek
  //TODO: točící kolečko
  //TODO: mezery mezi inputy, hláškami...
  //TODO: patička až na spod
  //TODO: favicon
  //TODO: logout podtržení


  //======Rozvoj======
  //TODO: skrytí odkazů => jsony budou mít název v md5 + nějaký klíč, my se budeme dorazovat na JS, kterému dáme tu md5 a on si doplní klíč a ten nám vrátí
  //TODO: ověřovat přihlášení cookie každých XX vteřin
  //TODO: denní limity linků
  //TODO: Hezké emaily - nějaká struktura, co nahrazuje proměnné, html templejty


  return (    
    <Router>
      <Header 
        loggedIn={loggedIn} 
        loginEmail={loginEmail} 
        setLoggedIn={handleLoggedIn} 
        setLoginEmail={handleLoginEmail} 
      />
      <CheckLogin 
        setLoggedIn={handleLoggedIn} 
        setLoginEmail={handleLoginEmail} 
      />
      <Routes>
          <Route 
            exact path="/" 
            element={<HomePage loggedIn={loggedIn} />}
          />
          <Route 
            path="/sent"
            element={<Sent />}
          />
      </Routes>
      <Footer /> 
    </Router>
  
    
  );
}

export default App;
