import sikmo from '../images/sikmo.svg';

function Footer( props ) {

  return (
		<footer>
			<div className="container">
				<div className="row">
					<div className="col text-align-center">
						<p className="h1">That is all 👋</p>
						<p className="mt-3">
							<a href="https://www.sikmo.cz/" target="_blank">
								<img src={sikmo} alt="developed by sikmo.cz" />
							</a>
						</p>
						<p>
							<small>Powered by <a href="https://wordpress.org/" target="_blank">WordPress</a></small>
						</p>
					</div>
				</div>
			</div>
		</footer>
	
  );
}

export default Footer;