import React, { useEffect } from 'react';
import copyIcon from '../images/copy.svg';
import goIcon from '../images/link.svg';
import removeIcon from '../images/delete.svg';


{/* <li>
<Link to={`/article/${article.id}`}>{article.title.rendered}</Link>
</li> */}

function LinksList(props) {
  //console.log( props.posts );

  useEffect(() => {
    props.fetchData();
  }, []);


  const removeLink = async (slug) => {
    fetch( `https://api.uloz.link/wp-json/ulozlink/v1/remove-link/?slug=${slug}`, 
      {
        headers: {  
          'Authorization': 'Bearer ' + props.loggedIn,
          'Content-Type': 'application/json'
        }
      })
      .then()
      .then(data => {

        if( data.code == "disabled" ) {
          //console.log( data.message );
        }
        //console.log( data );
        props.fetchData();
      });
};

  const copyLink = async (textToCopy) => {
    await navigator.clipboard.writeText( textToCopy );
  };

  return (
    <section>
      <div className="container">
        <div className="row mb-3">
          <div className="col text-align-center">
            <h2>Manage your links</h2>
          </div>
        </div>
        <div className="links-list card">
          <div className="row links-list__header">
            <div className="col links-list__item">
              <div className="links-list__col">
                <p><strong>Original link</strong></p>
              </div>
              <div className="links-list__col">
                <p><strong>New link</strong></p>
              </div>
              <div className="links-list__col">
                <p><strong>Actions</strong></p>
              </div>
            </div>
          </div>
          <div className="row links-list__body">
            <div className="row links-list__body">
              {props.posts.map(article => (
                <div className="col links-list__item" key={article.id}>
                  <div className="links-list__col">
                    <p>{article.title}</p>
                  </div>
                  <div className="links-list__col">
                    <p><strong>{article.shorLink}</strong></p>
                  </div>
                  <div className="links-list__col">
                    <ul className="action-list">
                      <li>
                        <button onClick={() => copyLink( article.shorLink)}>
                          <img src={copyIcon} alt="Copy link to clipboard"/>
                        </button>
                      </li>
                      <li>
                        <a href={article.shorLink} target="_blank">
                          <img src={goIcon} alt="Go to link"/>
                        </a>
                      </li>
                      <li>
                      <button onClick={() => removeLink( article.shorLink )}>
                        <img src={removeIcon} alt="Remove link"/>
                      </button>
                      </li>
                    </ul>
							  	</div>
							  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
    

  );
}

export default LinksList;