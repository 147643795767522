
function Login() {
  return (
    <main>
      <section className="sent">
        <div className="container">
          <h1>We sent you an email!</h1>
          <p>
            We sent you an email an email with a login link, please check your email inbox.
          </p>
        </div>
      </section>
    </main>
  );
}

export default Login;
