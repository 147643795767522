import logo from '../images/ulozlink.svg';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

function Header( props ) {

  const logout = async (event) => {
    event.preventDefault();
    Cookies.remove('api_key');
    props.setLoggedIn( false );
    Cookies.remove('login_email')
    props.setLoginEmail( false );
  }

  let mail = decodeURIComponent( props.loginEmail );

  return (
    <header>
      <div className="container">
        <div className="row row--align-items-center">
          <div className="col">
            <Link to={`/`}>
              <img src={logo} alt="uloz.link logo"></img>
            </Link>
          </div>
          <div className="col">
						<p>
              { props.loggedIn ?
              <span>
                <span>Logged as: <strong>{mail}</strong></span>
                &nbsp;<a href="#logout" onClick={logout}>(logout)</a>
              </span>
              :
              <a href="#login">Login</a>
              }
						</p>
					</div>
        </div>
      </div>
    </header>
  );
}

export default Header;