const fetchData = (loggedIn, setPosts) => {
  fetch( `https://api.uloz.link/wp-json/wp/v2/link`, 
    {
      headers: {  
        'Authorization': 'Bearer ' + loggedIn,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((data) => setPosts(data))
    .catch((error) => console.error(error));
};

export { fetchData };