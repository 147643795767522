// === React ===
import React, { useState, useEffect } from 'react';

//=== Components ===

//=== Functions ===

//=== Images ===
import warningIcon from '../images/warning.svg';
import copyIcon from '../images/copy.svg';


function isValidHttpUrl(string) {
  try {
    const newUrl = new URL(string);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
}

function AddLink( props ) {

  const [linkValue, setLinkValue] = useState('');
  const [shortLink, setShortLink] = useState('');
  const [loadingForm, setLoadingForm] = useState( false );
  const [formMessage, setFormMessage] = useState( false );

  const clearForm = async (event) => {
    event.preventDefault();
    setLinkValue("");
    setShortLink("");
  }

  const copyShortLink = async (event) => {
    event.preventDefault();
    await navigator.clipboard.writeText(shortLink);
  }

  const typingLink = async (val) => {
    setFormMessage( "" );
    setLinkValue( val );
  }

  useEffect(() => {
    if( !props.loggedIn ) {
      setLinkValue("");
      setShortLink("");
    }
  });

  useEffect(() => {
    if( linkValue == "" ) {
      setFormMessage("");
    }
  });


  const saveLink = async (event) => {
    event.preventDefault();

    setShortLink("");

    if( loadingForm ) { return; }

    if( ! props.loggedIn ) { return; }
    
    if( ! isValidHttpUrl( linkValue ) ) { 
      setFormMessage( "It looks like you didn't enter a valid url. Try entering link with the https:// prefix" );
      return;
    }

    setLoadingForm(true);
   
    fetch( `https://api.uloz.link/wp-json/ulozlink/v1/new-link/?link=${linkValue}`, 
    {
      headers: {  
        'Authorization': 'Bearer ' + props.loggedIn,
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      setTimeout(() => {
        props.fetchData();
        setShortLink( data.shortLink );
        setLinkValue("");
        setLoadingForm(false);
      }, 1500);
    });
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="h1 text-align-center">Get your link</h2>
          </div>
        </div>

        <div className="container container--small">
          <div className="row mt-3">
            <div className="col">
              <form className="doShortLinkForm" onSubmit={saveLink}>
                <input value={linkValue} onChange={ (event) => typingLink(event.target.value) } className="input input--big" type="text" name="longLink" data-longlink placeholder="Paste you link here" required="required" {...(!props.loggedIn  ? { 'disabled': 'disabled' } : {} ) } />
                <button type="submit" className={`button button--big ${loadingForm ? 'button--loading' : null}`} name="doShortLink" data-doshortlink="true" {...(!props.loggedIn ? { 'disabled': 'disabled' } : {} ) } {...(loadingForm ? { 'disabled': 'disabled' } : {} ) }>get my link</button>
              </form>
              { linkValue != "" ?
              <p className="text-align-center mt-2">
                <a href="#" className="clear-form" data-clearform onClick={clearForm}>clear form</a>
              </p>
              : null }

              { formMessage ?
              <div className="mt-2">
                <p className="message text-align-center">
                  <img src={warningIcon} alt="warning icon" /> 
                  {formMessage}
                </p>
              </div>
              : null}

              { shortLink ?
              <div className="text-align-center mt-2">
                <p className="message message--finished-link">
                  <strong>{shortLink}</strong>
                  <a className="button" href="#" data-copylink onClick={copyShortLink}>
                    <img src={copyIcon} alt="copy link icon" />
                    <span>copy link</span>
                  </a>
                </p>
              </div>
              : null }
            </div>
          </div>
        </div>
        { !props.loggedIn ?
        <div className="mt-2">
          <p className="message text-align-center">
            <img src={warningIcon} alt="warning icon" /> 
            This app is made to be used by real people. Only logged in users can use our features.
          </p>
        </div>
        : null}
      </div>
    </section>
    /* <form onSubmit={handleSubmit}>
      <label>
        Enter some data:
        <input
          type="text"
          value={linkValue}
          onChange={(event) => setlinkValue(event.target.value)}
        />
      </label>
      <button type="submit">Send</button>
    </form> */
  );
}

export default AddLink;